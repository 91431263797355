import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import _ from 'lodash';
import { Button, Icon, Select, TextField } from '@seeqdev/qomponents';
import { useFlux } from '@/core/hooks/useFlux.hook';
import { IconSelect } from '@/core/IconSelect.molecule';
import { KEY_CODES } from '@/main/app.constants';
import { getTypeTranslation } from '@/utilities/utilities';
import { SORT_BY_OPTIONS } from '@/search/search.constants';
import { doTrack } from '@/track/track.service';
import {
  clear,
  getStoreForPane,
  searchItems,
  setAdvancedMode,
  setDescriptionFilter,
  setIsExactName,
  setNameFilter,
  setSelectedDatasources,
  setSelectedTypes,
  setSortBy,
} from '@/search/search.actions';
import { SelectOptionIF } from '@/administration/usage/UsageTab.page';
import { Checkbox } from '@/core/Checkbox.atom';
import { Datasource, SearchFiltersProps } from '@/search/search.types';
import { AnyProperty } from '@/utilities.types';
import { ActiveFilters } from '@/search/ActiveFilters';
import { SeeqNames } from '@/main/app.constants.seeqnames';

const SEARCH_TYPES_TO_COMBINE = {
  [SeeqNames.Types.CalculatedCondition]: SeeqNames.Types.Condition,
  [SeeqNames.Types.StoredCondition]: SeeqNames.Types.Condition,
  [SeeqNames.Types.CalculatedSignal]: SeeqNames.Types.Signal,
  [SeeqNames.Types.StoredSignal]: SeeqNames.Types.Signal,
  [SeeqNames.Types.CalculatedScalar]: SeeqNames.Types.Scalar,
  [SeeqNames.Types.LiteralScalar]: SeeqNames.Types.Scalar,
};

/**
 * This will prevent duplicate options when searching by item type and ensure that
 * both stored and calculated versions of a type are included in the search
 */
const combineSearchTypes = (searchTypes: string[]) =>
  Array.from(
    new Set(
      searchTypes.map((type) => {
        const combinedType = SEARCH_TYPES_TO_COMBINE[type];

        return combinedType ? combinedType : type;
      }),
    ),
  );

export const SearchFilters: React.FunctionComponent<SearchFiltersProps> = (props) => {
  const { pane, scopeIds, excludeGloballyScoped = false } = props;
  const searchTypes = combineSearchTypes(props.searchTypes);
  const { t } = useTranslation();

  const nameFilterRef = useRef<HTMLInputElement>(null);
  const store = getStoreForPane(pane);

  const focusNameInput = () => nameFilterRef.current?.focus();
  useEffect(focusNameInput, []);

  const {
    nameFilter,
    descriptionFilter,
    searching,
    currentAsset,
    typeFilter,
    datasourceFilter,
    sortBy,
    isAdvancedMode,
    datasources,
    isExactName,
  } = useFlux(store);

  const search = async () => {
    await searchItems(pane, searchTypes, scopeIds, excludeGloballyScoped);

    const searchSummary: AnyProperty<string | number | boolean> = {
      advancedMode: isAdvancedMode,
      nameSpecified: !!nameFilter,
      descriptionSpecified: !!descriptionFilter,
      assetSpecified: !!currentAsset,
      datasourceFilterCount: _.size(datasourceFilter),
      sortBy,
    };
    _.forEach(
      searchTypes,
      (searchType) => (searchSummary[`typeFilter-${searchType}`] = _.includes(typeFilter, searchType)),
    );

    doTrack('Search', 'Search Complete', JSON.stringify(searchSummary));
  };

  /**
   * Using "searchTypes", make an array of objects the react-select can use.
   */
  const searchTypesOptions: SelectOptionIF<string>[] = _.map(searchTypes, (type) => ({
    label: t(getTypeTranslation(type)),
    value: type,
  }));

  /**
   * Using "datasources" (from the Flux store), make an array of objects the react-select can use.
   */
  const datasourcesOptions = _.chain(datasources)
    .map((datasource) => ({
      label: datasource.name,
      value: datasource,
    }))
    .sortBy((datasource) => datasource.label?.toLowerCase())
    .value();

  const activeFilters = [
    { label: 'Description', filter: descriptionFilter, onClear: () => setDescriptionFilter(pane, '') },
    { label: 'Type', filter: typeFilter, onClear: () => setSelectedTypes(pane, []) },
    { label: 'Datasource', filter: datasourceFilter, onClear: () => setSelectedDatasources(pane, []) },
    { label: 'Sort', filter: sortBy, onClear: () => setSortBy(pane, '') },
  ];

  return (
    <div
      className={classNames('card card-default', {
        borderGray: pane === 'modal',
      })}>
      <div className="card-body" data-testid="searchPanel">
        <TextField
          extraClassNames="mb3 width-maximum"
          id="searchInput"
          name="nameFilter"
          testId="nameFilter"
          placeholder={t('SEARCH_DATA.NAME_CONTAINS')}
          value={nameFilter}
          onChange={(e) => setNameFilter(pane, e.target.value)}
          onKeyUp={(e) => e.keyCode === KEY_CODES.ENTER && search()}
          ref={nameFilterRef}
        />

        <Checkbox
          label="EXACT_MATCH_CHECKBOX"
          isChecked={isExactName}
          classes="mb10"
          id="EXACT_MATCH_CHECKBOX"
          onChange={() => setIsExactName(pane, !isExactName)}
        />

        {!isAdvancedMode && <ActiveFilters selectedFilters={activeFilters} actionAfterFilterCleared={search} />}

        {isAdvancedMode && (
          <div className="advancedFormWrapper">
            <TextField
              extraClassNames="mb10 width-maximum"
              id="searchInput"
              name="descriptionFilter"
              testId="descriptionFilter"
              placeholder={t('SEARCH_DATA.DESCRIPTION_CONTAINS')}
              value={descriptionFilter}
              onKeyUp={(e) => e.keyCode === KEY_CODES.ENTER && search()}
              onChange={(e) => setDescriptionFilter(pane, e.target.value)}
            />
            {/* Item type filter */}
            <div data-testid="typeFilter" className="mb10 multiSelectTwoLine">
              <Select
                isMulti={true}
                isClearable={true}
                closeMenuOnSelect={false}
                isSearchable={true}
                options={searchTypesOptions}
                menuPortalTarget={document.body}
                placeholder={t('SEARCH_DATA.ALL_TYPES')}
                value={_.map(typeFilter, (type) => ({ label: t(getTypeTranslation(type)), value: type }))}
                onChange={(selectedTypes: SelectOptionIF<string>[]) => {
                  setSelectedTypes(pane, selectedTypes);
                }}
              />
            </div>
            {/* Datasource filter */}
            <div data-testid="datasourceFilter" className="mb10 multiSelectTwoLine">
              <Select
                isMulti={true}
                isClearable={true}
                closeMenuOnSelect={false}
                isSearchable={true}
                options={datasourcesOptions}
                menuPortalTarget={document.body}
                placeholder={t('SEARCH_DATA.ALL_DATASOURCES')}
                value={_.map(datasourceFilter, (datasource) => ({ label: datasource.name, value: datasource }))}
                onChange={(selectedDatasources: SelectOptionIF<Datasource>[]) => {
                  setSelectedDatasources(pane, selectedDatasources);
                }}
              />
            </div>

            <IconSelect
              name="sortByFilter"
              value={sortBy}
              wrapperClasses="mb10"
              selectOptions={_.map(SORT_BY_OPTIONS, (option, key) => ({
                text: `SEARCH_DATA.SORT_BY_OPTIONS.${key}`,
                value: option,
              }))}
              onChange={(sort) => setSortBy(pane, _.toString(sort.value))}
            />
          </div>
        )}

        <div className="flexColumnContainer flexSpaceBetween">
          <Button
            label={t('SEARCH_DATA.RESET')}
            onClick={() => {
              clear(pane, searchTypes, false, scopeIds, excludeGloballyScoped);
              nameFilterRef.current?.focus();
            }}
            testId="resetButton"
          />
          <div>
            <a
              className="force-link-color mr10 cursorPointer"
              data-testid="moreFiltersButton"
              onClick={() => setAdvancedMode(pane, !isAdvancedMode)}>
              {t('SEARCH_DATA.FILTER_AND_SORT')}
              <Icon icon={isAdvancedMode ? 'fa-angle-up' : 'fa-angle-down'} extraClassNames="ml2" />
            </a>
            <Button
              id="spec-search-btn"
              label={t('SEARCH')}
              variant="theme"
              icon={searching ? 'fa-spinner fa-spin-pulse' : 'fa-search'}
              iconPrefix={searching ? 'fa-solid' : ''}
              disabled={searching}
              iconStyle="white"
              type="submit"
              onClick={search}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
