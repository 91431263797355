export const SEARCH_PER_PAGE = 35;
export const MAX_RETURNED_RESULTS = 1000;
export type SearchPanes = 'main' | 'modal';
export type SearchModes = 'overview' | 'search' | 'tree' | 'pinned' | 'recent' | 'asset-groups' | 'usages';

export const SORT_BY_OPTIONS = {
  DEFAULT: '',
  NAME: 'Name',
  DESCRIPTION: 'Description',
};
export const SEARCH_BREADCRUMB = {
  name: 'SEARCH_DATA.SEARCH_RESULTS',
  id: 'SEARCH',
  type: 'VIEW_MODE',
};
export const HOME_BREADCRUMB = { id: '' };
export const SEARCH_USAGES_BREADCRUMB = {
  name: 'SEARCH_DATA.USAGES',
  id: 'ITEM_USAGES',
  type: 'VIEW_MODE',
};
export const SEARCH_RESULT_TYPES = {
  ITEMS: 'items',
  ASSETS: 'assets',
};

export const DATASOURCES_TO_EXCLUDE = ['Auth', 'LDAP'];
